<template>
  <div class="container mx-auto my-4 p-4" style="min-height: 600px">
    <el-card>
      <el-breadcrumb class="mb-3" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>教师后台</el-breadcrumb-item>
        <el-breadcrumb-item>班级列表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-table :data="list" border>
        <el-table-column prop="name" label="班级名称" />
        <el-table-column prop="student_num" label="学生数量(人)" />
        <el-table-column fiexd="right" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="showClassCourse(scope.row)"
              >发送课程</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="sendCourseDialogTitle" :visible.sync="sendCourseDialog">
      <el-table :data="courseListData">
        <el-table-column prop="name" label="课程"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="sendCourse(scope.row.id)">发送</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { getClassList, sendCourseToStudent } from "@/api/backage";
import { list } from "@/api/course";
export default {
  name: "Backage",
  data() {
    return {
      list: [],
      courseListData: [],
      sendCourseDialog: false,
      sendCourseDialogTitle: '',
      currentClassID: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getClassList().then((res) => {
        this.list = res.data.list;
      });
      list().then(res => {
        this.courseListData = res.data.list
      })
    },
    showClassCourse(row) {
      this.currentClassID = row.id
      this.sendCourseDialog = true
      this.sendCourseDialogTitle = `给【${row.name}】的学生发送课程`
    },
    sendCourse(id) {
      console.log(id)
      const loading = this.$loading({
        lock: true,
        text: '发送中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      sendCourseToStudent({ course_id: id, class_id: this.currentClassID }).then(res => {
        this.$message({
          message: res.msg,
          type: 'success',
        });
      }).finally(() => {
        loading.close();
      })
    }
  },
};
</script>
<style>
</style>
